<template>
  <div class="home" @click="goto">
    <div class="bg"></div>
    <div class="center">
      <div class="top">
        <div>中国领先的智慧水务</div>
        <div>智慧城市解决方案与技术服务提供商</div>
      </div>
      <!-- <div class="bottom">
        <div class="left">TRUSTED</div>
        <div class="text" ref="text">
          <div :class="dong ? 'dong1' : ''">I</div>
          <div :class="dong ? 'dong2' : ''">N</div>
          <div :class="dong ? 'dong3' : ''">T</div>
          <div :class="dong ? 'dong4' : ''">E</div>
          <div :class="dong ? 'dong5' : ''">R</div>
          <div :class="dong ? 'dong6' : ''">N</div>
          <div :class="dong ? 'dong7' : ''">E</div>
          <div :class="dong ? 'dong8' : ''">T</div>
          <div :class="dong ? 'dong9' : ''">&ensp;</div>
          <div :class="dong ? 'dong10' : ''">O</div>
          <div :class="dong ? 'dong11' : ''">F</div>
          <div :class="dong ? 'dong12' : ''">&ensp;</div>
          <div :class="dong ? 'dong13' : ''">T</div>
          <div :class="dong ? 'dong14' : ''">H</div>
          <div :class="dong ? 'dong15' : ''">I</div>
          <div :class="dong ? 'dong16' : ''">N</div>
          <div :class="dong ? 'dong17' : ''">G</div>
          <div :class="dong ? 'dong18' : ''">S</div>
          <div :class="dong ? 'meng' : ''" ref="meng"></div>
        </div>
        <div class="right">TECHNOLOGY PROVIDER</div>
      </div> -->
    </div>
    <div class="shubiao" ref="shubiao"></div>
    <div class="lis">
      <div class="dian"></div>
      <div class="dian"></div>
      <div class="dian"></div>
    </div>
    <!-- <div class="dianji" @click="test">点击</div>
    <transition name="fade-mainMenu">
      <div class="test" ref="test" v-show="show"></div>
    </transition>
    <div class="xx" ref="xx"></div>

    <div class="yy">
      <ICountUp :endVal="endVal" :options="options" />
    </div> -->
  </div>
</template>

<script>
//import ICountUp from "vue-countup-v2";
export default {
  // components: {
  //   ICountUp,
  // },
  data() {
    return {
      index: 0,
      list: [
        { name: "INTERNET OF THINGS " },
        { name: "SMART  CITY " },
        { name: "VISUALIZATION " },
        { name: "BIG DATA " },
        { name: "INTELLIGENT DEVICE " },
      ],
      timer: null,
      show: true,

      dong: true,
      Timeout: null,

      startTime: 0, // 翻屏起始时间
      endTime: 0, // 上一次翻屏结束时间

      goTimeout: null,
    };
  },
  methods: {
    // xx() {
    //   this.$router.push("/index");
    // },
    goindex(item) {
      this.startTime = new Date().getTime();
      if (this.startTime - this.endTime > 1000) {
        if (item.wheelDelta <= 0) {
          this.goTimeout = setTimeout(() => {
            this.$router.push("/index");
          }, 100);
        }
      }
      this.endTime = new Date().getTime();
    },
    goto() {
      this.$router.push("/index");
    },

    xunhuan() {
      this.timer = setInterval(() => {
        this.dong = false;
        this.Timeout = setTimeout(() => {
          this.dong = true;
        }, 500);
      }, 2200);
    },
  },
  mounted() {
    document.addEventListener("mousewheel", this.goindex, false);
    this.xunhuan();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearTimeout(this.Timeout);
    clearTimeout(this.goTimeout);

    document.removeEventListener("mousewheel", this.goindex, false);
  },

  // mounted() {
  //   let xx = this.$refs.text.clientWidth;
  //   console.log("object :>> ", this.$refs.text.clientWidth);
  //   this.$refs.text.style.width = "0px";
  //   setTimeout(() => {
  //     this.$refs.text.style.width = xx + "px";
  //   }, 1000);
  // },
};
</script>

<style lang="scss" scoped>
.yy {
  width: 100px;
  height: 100px;
}
.home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  position: relative;

  font-family: TenSans;
  .bg {
    // width: 1920px;
    // height: 1080px;
    width: 100%;
    height: 100%;
    background-image: url("../assets/imgs/home/homebg.png");
    background-size: 100% 100%;
  }

  .center {
    // width: 600px;
    height: 200px;
    // background-color: pink;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -40px;
    .top {
      //  width: 336px;
      height: 72px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      font-size: 30px;

      font-family: Alibaba PuHuiTi;
    }
    .bottom {
      margin-top: 20px;
      //width: 580px;
      height: 22px;
      display: flex;
      font-weight: 700;
      font-size: 22px;
      color: rgba(68, 143, 255, 1);
      justify-content: space-between;
      .text {
        height: 30px;
        color: #fff;
        margin: 0 10px;
        // overflow: hidden;
        // transition: all 1s linear;
        display: flex;
        //  overflow: hidden;
        position: relative;
        //opacity: 0;
        // animation: test 1s linear 2s infinite;
        .dong1 {
          opacity: 0;
          animation: goxx 0.5s linear both;
        }
        .dong2 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.1s;
        }
        .dong3 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.2s;
        }
        .dong4 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.2s;
        }
        .dong5 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.2s;
        }
        .dong6 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.3s;
        }
        .dong7 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.3s;
        }
        .dong8 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.3s;
        }
        .dong9 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.3s;
        }
        .dong10 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.4s;
        }
        .dong11 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.4s;
        }
        .dong12 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.4s;
        }
        .dong13 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.5s;
        }
        .dong14 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.5s;
        }
        .dong15 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.6s;
        }
        .dong16 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.6s;
        }
        .dong17 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.6s;
        }
        .dong18 {
          opacity: 0;
          animation: goxx 0.5s linear both 0.6s;
        }

        .meng {
          width: 2px;

          height: 26px;
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          // transition: all 1s linear;
          opacity: 0;
          animation: goright 2.2s linear both;
        }
      }
    }
  }
  .shubiao {
    width: 24px;
    height: 38px;
    background-image: url("../assets/imgs/home/shubiao.png");
    background-size: 100% 100%;
    position: absolute;
    bottom: 80px;
    right: 948px;
    animation: run 2s linear infinite;
  }
  .lis {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .dian {
      width: 4px;
      height: 4px;
      background: rgba(255, 255, 255, 1);
      border-radius: 50%;
      margin-top: 10px;
    }
  }
  .dianji {
    position: absolute;

    background-color: pink;
    left: 300px;
    bottom: 500px;
    color: red;
  }
  .test {
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: pink;
    left: 30px;
    bottom: 30px;

    transition: all 0.3s linear;
  }
  .xx {
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: skyblue;
    left: 300px;
    bottom: 30px;
    transition: all 1s linear;
  }
}

@keyframes run {
  0% {
    bottom: 80px;
  }
  50% {
    bottom: 90px;
  }
  100% {
    bottom: 80px;
  }
}

@keyframes goright {
  0% {
    left: 0;
    opacity: 1;
  }
  20% {
    left: 13.5vw;
    opacity: 1;
  }
  50% {
    left: 13.5vw;
    opacity: 1;
  }
  80% {
    left: 13.5vw;
    opacity: 0.5;
  }
  100% {
    left: 13.5vw;
    opacity: 0;
  }
}

@keyframes goxx {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes test {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
  }
}

.fade-mainMenu-enter-active {
  animation: fade-mainMenu-in 2s linear;
}

// .fade-mainMenu-leave-active {
//   animation: fade-mainMenu-out 2s;
// }
@keyframes fade-mainMenu-in {
  0% {
    height: 200px;
  }

  100% {
    height: 100px;
  }
}

@keyframes fade-mainMenu-out {
  0% {
    height: 0px;
  }

  100% {
    height: 300px;
  }
}
</style>